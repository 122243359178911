import { Route, Routes } from 'react-router-dom'
import { AdvanceBorderoProvider } from './context/AdvanceBordero'
import { lazy, Suspense } from 'react'
import FullSizeLoader from './components/FullSizeLoader'
import ProtectedRoute from './components/ProtectedRoute'
import { Permissions } from './types/Permissions'
const FileDispatchList = lazy(() => import('./pages/Vendor/FileDispatchList'))
const Logout = lazy(() => import('./pages/Logout'))
const AccountSettings = lazy(() => import('./pages/AccountSettings'))
const FileDispatchDetails = lazy(() => import('./pages/Vendor/FileDispatchDetails'))
const BatchUpload = lazy(() => import('./pages/Vendor/BatchUpload'))
const AdvanceBordero = lazy(() => import('./pages/Vendor/AdvanceBordero'))
const BorderoDetails = lazy(() => import('./pages/Vendor/BorderoDetails'))
const BorderoHistory = lazy(() => import('./pages/Vendor/BorderoHistory'))
const RegisterComplement = lazy(() => import('./pages/Vendor/RegisterComplement'))
const VendorSettlementInvoices = lazy(() => import('./pages/Vendor/SettlementInvoices'))
const FunderSettlementInvoices = lazy(() => import('./pages/Funder/SettlementInvoices'))
const Taxes = lazy(() => import('./pages/Funder/Taxes'))
const VendorBatchUpload = lazy(() => import('./pages/Funder/VendorBatchUpload'))
const VendorList = lazy(() => import('./pages/Funder/VendorList'))
const BorderosList = lazy(() => import('./pages/Funder/Borderos'))
const NotFoundPage = lazy(() => import('./pages/NotFoundPage'))
const HomeRedirect = lazy(() => import('./pages/HomeRedirect'))
const VendorFileList = lazy(() => import('./pages/Funder/VendorFileList'))

const Router = () => {
  return (
    <Suspense fallback={<FullSizeLoader withSidebar />}>
      <Routes>
        <Route
          path="/advance-bordero"
          element={
            <ProtectedRoute
              permission={[Permissions.ReadAdvanceBordero, Permissions.WriteAdvanceBordero]}
            >
              <AdvanceBorderoProvider>
                <AdvanceBordero />
              </AdvanceBorderoProvider>
            </ProtectedRoute>
          }
        />
        <Route
          path="/advance-bordero/:uuid"
          element={
            <ProtectedRoute
              permission={[Permissions.ReadAdvanceBordero, Permissions.WriteAdvanceBordero]}
            >
              <BorderoDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path="/bordero-history"
          element={
            <ProtectedRoute permission={[Permissions.ReadBordero, Permissions.ReadAdvanceBordero]}>
              <BorderoHistory />
            </ProtectedRoute>
          }
        />
        <Route
          path="/document-batch-upload"
          element={
            <ProtectedRoute permission={[Permissions.TaxDocumentUpload]}>
              <BatchUpload />
            </ProtectedRoute>
          }
        />
        <Route
          path="/follow-dispatch/:uuid"
          element={
            <ProtectedRoute permission={[Permissions.ReadDocumentDispatch]}>
              <FileDispatchDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path="/follow-dispatch"
          element={
            <ProtectedRoute permission={[Permissions.ReadDocumentDispatch]}>
              <FileDispatchList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/settlement-invoices"
          element={
            <ProtectedRoute
              permission={[Permissions.ReadInstallments, Permissions.WriteInstallments]}
            >
              <VendorSettlementInvoices />
            </ProtectedRoute>
          }
        />
        <Route
          path="/taxes"
          element={
            <ProtectedRoute permission={[Permissions.AdminReadTaxes, Permissions.AdminWriteTaxes]}>
              <Taxes />
            </ProtectedRoute>
          }
        />
        <Route
          path="/vendor-batch-upload"
          element={
            <ProtectedRoute permission={[Permissions.AdminVendorsUpload]}>
              <VendorBatchUpload />
            </ProtectedRoute>
          }
        />
        <Route
          path="/vendor-file-list"
          element={
            <ProtectedRoute permission={[Permissions.AdminVendorsUpload]}>
              <VendorFileList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/vendors"
          element={
            <ProtectedRoute
              permission={[Permissions.AdminReadVendor, Permissions.AdminWriteVendor]}
            >
              <VendorList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/borderos"
          element={
            <ProtectedRoute permission={[Permissions.AdminReadVendor]}>
              <BorderosList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/settlement-invoices-funder"
          element={
            <ProtectedRoute permission={[Permissions.AdminReadInstallments]}>
              <FunderSettlementInvoices />
            </ProtectedRoute>
          }
        />
        <Route path="/logout" element={<Logout />} />
        <Route path="/finish-register" element={<RegisterComplement />} />
        <Route path="/account-settings" element={<AccountSettings />} />
        <Route path="/" element={<HomeRedirect />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Suspense>
  )
}

export default Router
