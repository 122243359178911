import { Permissions } from '@/types/Permissions'
import {
  FactCheckOutlined,
  LocalAtm,
  LogoutOutlined,
  SettingsOutlined,
  Percent,
  UploadFileOutlined,
  RequestQuoteOutlined
} from '@mui/icons-material'
import React from 'react'

export interface MenuHeaderInterface {
  subheader: string
  menu: MenuInterface[]
}

export interface MenuInterface {
  text?: string
  icon: React.ReactNode
  submenu: SubmenuInterface[]
  path?: string
  match?: MatchInterface
  permissions?: string[]
}

export interface SubmenuInterface {
  text: string
  icon?: React.ReactNode
  path: string
  match: MatchInterface
  permissions: string[]
}

export interface MatchInterface {
  path: string
  end: boolean
}

const menu: MenuHeaderInterface[] = [
  {
    subheader: 'Cessão Pulverizada',
    menu: [
      {
        text: 'Envio de títulos',
        icon: <FactCheckOutlined />,
        submenu: [
          {
            text: 'Enviar arquivos',
            path: '/document-batch-upload',
            match: {
              path: '/document-batch-upload',
              end: true
            },
            permissions: [Permissions.TaxDocumentUpload]
          },
          {
            text: 'Histórico do arquivo',
            path: '/follow-dispatch',
            match: {
              path: '/follow-dispatch',
              end: true
            },
            permissions: [Permissions.ReadDocumentDispatch]
          }
        ]
      },
      {
        text: 'Gestão de borderôs',
        icon: <LocalAtm />,
        submenu: [
          {
            text: 'Antecipar borderôs',
            path: '/advance-bordero',
            match: {
              path: '/advance-bordero',
              end: true
            },
            permissions: [Permissions.ReadAdvanceBordero, Permissions.WriteAdvanceBordero]
          },
          {
            text: 'Histórico',
            path: '/bordero-history',
            match: {
              path: '/bordero-history',
              end: true
            },
            permissions: [Permissions.ReadBordero]
          }
        ]
      },
      {
        text: 'Liquidação de notas',
        icon: <FactCheckOutlined />,
        path: '/settlement-invoices',
        match: {
          path: '/settlement-invoices',
          end: true
        },
        permissions: [Permissions.ReadInstallments, Permissions.WriteInstallments],
        submenu: []
      },
      {
        text: 'Cadastro de clientes',
        icon: <UploadFileOutlined />,
        submenu: [
          {
            text: 'Enviar arquivos',
            path: '/vendor-batch-upload',
            match: {
              path: '/vendor-batch-upload',
              end: true
            },
            permissions: [Permissions.AdminVendorsUpload]
          },
          {
            text: 'Histórico de arquivos',
            path: '/vendor-file-list',
            match: {
              path: '/vendor-file-list',
              end: true
            },
            permissions: [Permissions.AdminVendorsUpload]
          },
          {
            text: 'Gerenciar cadastros dos cedentes',
            path: '/vendors',
            match: {
              path: '/vendors',
              end: true
            },
            permissions: [Permissions.AdminReadVendor, Permissions.AdminWriteVendor]
          }
        ]
      },
      {
        text: 'Taxas',
        icon: <Percent />,
        path: '/taxes',
        match: {
          path: '/taxes',
          end: true
        },
        permissions: [Permissions.AdminReadTaxes, Permissions.AdminWriteTaxes],
        submenu: []
      },
      {
        text: 'Gestão de borderôs',
        icon: <RequestQuoteOutlined />,
        path: '/borderos',
        match: {
          path: '/borderos',
          end: true
        },
        permissions: [Permissions.AdminReadTaxes],
        submenu: []
      },
      {
        text: 'Liquidação de notas',
        icon: <FactCheckOutlined />,
        path: '/settlement-invoices-funder',
        match: {
          path: '/settlement-invoices-funder',
          end: true
        },
        permissions: [Permissions.AdminReadInstallments],
        submenu: []
      }
    ]
  },
  {
    subheader: 'Minha conta',
    menu: [
      {
        text: 'Configurações',
        icon: <SettingsOutlined />,
        submenu: [
          {
            text: 'Conta',
            path: '/account-settings',
            match: {
              path: '/account-settings',
              end: true
            },
            permissions: ['disabled']
          }
        ]
      },
      {
        text: 'Sair',
        icon: <LogoutOutlined />,
        path: '/logout',
        match: {
          path: '/logout',
          end: true
        },
        submenu: [],
        permissions: ['disabled']
      }
    ]
  }
]

export default menu
